<template>
    <v-container fluid>
        <v-card flat light>
            <v-row align="center">
                <v-col cols="12" sm="6" class="d-flex justify-center">
                    <v-img src="/img/error_pages/404.png" contain></v-img>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex justify-start">
                    <div>
                        <div class="display-2 font-weight-bold">{{$t('oops')}}</div>
                        <p class="title">{{$t('this_page_does_not_exist')}}</p>
                        <p class="body-2">{{$t('please_check_url_correct_or_click_to_return_home')}}
                        </p>
                        <router-link :to="{name: 'dashboard'}" class="btn btn-hover-shine btn-transition btn-primary btn-back">{{$t('back')}}
                        </router-link>
                    </div>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>
<script>
    export default {
        name: "NotFound"
    };

</script>
